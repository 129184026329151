body, input, button {
  background-color: #121212;
  color: #fff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


.admin-header {
  background-color: #333;
  padding: 20px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.admin-panel {
  max-width: 100%;
  height: 100vh;
  padding: 20px;
  border-radius: 10px;
  background-color: #252525;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  overflow: auto; /* Hide overflow to prevent unnecessary scrolling */
}

.user-management {
  height: calc(90vh - 150px); /* Adjusted to leave space for header and search bar */
  padding-top: 20px; /* Padding from the search bar */
  overflow-y: auto; /* Enable vertical scrolling for user list */
}
/* Customize scrollbar */
.user-management::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Track */
.user-management::-webkit-scrollbar-track {
  background: #333; /* Dark background color for the track */
}

/* Handle */
.user-management::-webkit-scrollbar-thumb {
  background: #666; /* Dark color for the handle */
}

/* Handle on hover */
.user-management::-webkit-scrollbar-thumb:hover {
  background: #999; /* Lighter color for the handle on hover */
}

.search-bar {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: calc(100% - 20px);
  background-color: #333;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.users {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.users li {
  background-color: #333;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s;
  min-width: 250px; /* Ensure each user card has minimum width */
}

.users li:hover {
  transform: scale(1.02);
}

.header-buttons {
  display: flex;
}

.header-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.header-button.active {
  background-color: #494343; /* Change to the desired highlight color */
}
.header-button.notactive {
  background-color: #7a7a7a; /* Change to the desired highlight color */
}


.header-button:not(:last-child) {
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
